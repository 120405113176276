import styled from 'styled-components'

import { BaseText, Shadow } from '../../../lib/sharedStyles'

export const Article = styled.article`
  ${Shadow}
  background: white;

  padding: 2rem 1.5rem;
  margin: 0 auto;
  max-width: 600px;
`

export const Heading = styled.h3`
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 3.5rem;

  margin-bottom: 0.5rem;
`

export const Time = styled.time`
  ${BaseText}
`

export const P = styled.p`
  ${BaseText}
`

export const CountdownWrapper = styled.div`
  margin: 5rem auto 0;
  max-width: 450px;
`
