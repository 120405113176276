import { useEffect, useState } from 'react'

const isClient = typeof window === 'object'

const getSize = () => {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }
}

// Adapted from https://usehooks.com/useWindowSize/
export const useWindowSize = (): {
  width: number | undefined
  height: number | undefined
} => {
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}
