import React, { ReactElement, useState } from 'react'

import * as Styles from './styles'
import TwitchEmbed from '../../TwitchEmbed'

const Desktop: React.FC<{
  votingComponent: ReactElement
  chattingComponent: ReactElement
  twitchUsername: string
}> = ({ votingComponent, chattingComponent, twitchUsername }) => {
  const [layout, setLayout] = useState<'vote' | 'chat'>('vote')

  return (
    <Styles.Root layout={layout}>
      <Styles.SuggestWrapper className="Participate__SuggestWrapper">
        <Styles.EmbedWrapper>{votingComponent}</Styles.EmbedWrapper>
      </Styles.SuggestWrapper>

      <Styles.WatchWrapper className="Participate__TwitchWrapper">
        <Styles.EmbedWrapper>
          <TwitchEmbed username={twitchUsername} />
        </Styles.EmbedWrapper>
      </Styles.WatchWrapper>

      <Styles.ChatWrapper className="Participate__ChatWrapper">
        <Styles.EmbedWrapper>{chattingComponent}</Styles.EmbedWrapper>
      </Styles.ChatWrapper>

      <Styles.ChangeViewRoot>
        <Styles.ChangeViewWrapper>
          <Styles.ChangeView isSelected={layout === 'vote'}>
            <Styles.ChangeViewButton onClick={() => setLayout('vote')}>
              Vote View
            </Styles.ChangeViewButton>
          </Styles.ChangeView>
          <Styles.ChangeView isSelected={layout === 'chat'}>
            <Styles.ChangeViewButton onClick={() => setLayout('chat')}>
              Chat View
            </Styles.ChangeViewButton>
          </Styles.ChangeView>
        </Styles.ChangeViewWrapper>
      </Styles.ChangeViewRoot>
    </Styles.Root>
  )
}

export default Desktop
