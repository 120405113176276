import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const MeetingPulseNotFound: React.FC<{ code?: string }> = ({ code }) => (
  <Root>
    <p>The session code {code} could not be found.</p>
  </Root>
)

export default MeetingPulseNotFound
