import { gql } from '@apollo/client'

export default gql`
  query GetSessionByCode($code: String!) {
    sessionCollection(where: { code: $code }) {
      items {
        title
        start
        end
        artistName
        artistUrl
        meetingPulseUrl
        deadSimpleChatUrl
        twitchUsername
        codeOfConduct {
          json
        }
        code
      }
    }
  }
`
