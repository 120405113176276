import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

import './reset.css'
import './index.css'

import App from './components/App'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  environment:
    window.location.hostname === 'psich.art' ? 'production' : 'development',
  dsn:
    'https://dbad49aca66547c2bd74bac9ba0ddcf0@o399368.ingest.sentry.io/5256350',
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
