import styled from 'styled-components'

export const Main = styled.main`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
`
