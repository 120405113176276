import React from 'react'

import * as Styles from './styles'
import { useSessionContext } from '../SessionContext'

const TwitchEmbed: React.FC<{ username: string | null }> = ({ username }) => {
  const result = useSessionContext()

  const link = result && result[0].deadSimpleChatUrl

  const linkWithUsername = `${link}?username=${username}`

  return (
    <>
      {!username && (
        <Styles.TextWrapper>
          <p>
            Please read the code of conduct and enter your username to start
            chatting with other participants.
          </p>
        </Styles.TextWrapper>
      )}

      {username && (
        <Styles.Iframe
          src={linkWithUsername}
          title="Dead Simple Chat embed"
          height="100%"
          width="100%"
        />
      )}
    </>
  )
}

export default TwitchEmbed
