import { css } from 'styled-components'

export enum Colors {
  Primary = '#020075',
  Secondary = '#F0FF00',
}

export const BaseText = css`
  font-size: 1.75rem;
  line-height: 2.75rem;
`

export const Shadow = css`
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.08);
`
