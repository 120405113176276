import React from 'react'

import MeetingEntryPoint from '../MeetingEntryPoint'
import * as Styles from './styles'

import downArrow from './down-arrow.svg'
import photoDesktop from './illustration.png'
import photoMobile from './illustration.png'

const Intro = () => (
  <Styles.TextWrapper>
    <Styles.MaxWidthWrapper>
      <Styles.AboveTheFold>
        <Styles.H1>Let’s create art together</Styles.H1>

        <Styles.DownArrowImg
          src={downArrow}
          alt="An arrow pointed downward, indicating that the user can scroll"
        />
      </Styles.AboveTheFold>
      <Styles.IntroText>
        PSiCHArt is a live and immersive online experience to collectively make
        art through creative thinking and collaboration.
      </Styles.IntroText>

      <Styles.ArtistPhotoFigure>
        <Styles.ArtistPhotoDesktop
          src={photoDesktop}
          alt="An artist painting in their studio"
        />
        <Styles.ArtistPhotoMobile
          src={photoMobile}
          alt="An artist painting in their studio"
        />
      </Styles.ArtistPhotoFigure>

      <Styles.H2>Let's create together</Styles.H2>

      <Styles.StepsOl>
        <Styles.StepLi>
          Join the conversation and converge onto great ideas to create on a
          canvas.
        </Styles.StepLi>
        <Styles.StepLi>
          Convince others to like your ideas to see it on the canvas, or guide
          the creativity by disliking ideas.
        </Styles.StepLi>
        <Styles.StepLi>
          Watch live from the artist’s studio to guide and inspire the artist as
          they create the ideas onto a canvas.
        </Styles.StepLi>
      </Styles.StepsOl>

      <Styles.H2>Join an event</Styles.H2>

      <Styles.P>Enter your session code to join.</Styles.P>

      <MeetingEntryPoint />

      <Styles.Footer>
        <Styles.FooterLink
          href="https://app.termly.io/document/privacy-policy/60444afa-661e-4c28-9fdd-4e6867269769"
          target="_blank"
          rel="noopener nofollow"
        >
          Privacy Policy
        </Styles.FooterLink>

        <Styles.FooterLink
          href="https://app.termly.io/document/terms-of-use-for-website/fcb86ec2-1679-4a21-bae6-17acdf13f211"
          target="_blank"
          rel="noopener nofollow"
        >
          Terms of Use
        </Styles.FooterLink>
      </Styles.Footer>
    </Styles.MaxWidthWrapper>
  </Styles.TextWrapper>
)

export default Intro
