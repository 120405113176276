import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'

import { CONTENTFUL_SPACE_ID, CONTENTFUL_ACCESS_TOKEN } from './constants'
import Intro from '../Intro'
import Participate from '../Participate'
import SentryTest from '../SentryTest'
import {SessionContextProvider} from '../SessionContext'
import * as Styles from './styles'

const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${CONTENTFUL_SPACE_ID}?access_token=${CONTENTFUL_ACCESS_TOKEN}`,
  cache: new InMemoryCache(),
})

const App = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Styles.Main>
        <Switch>
          <Route path="/" exact>
            <Intro />
          </Route>
          <Route path="/participate" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/participate/:code">
            <SessionContextProvider>
              <Participate />
            </SessionContextProvider>
          </Route>
          <Route path="/sentry-test">
            <SentryTest />
          </Route>
        </Switch>
      </Styles.Main>
    </BrowserRouter>
  </ApolloProvider>
)

export default App
