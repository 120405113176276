import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import getSessionByCodeQuery from '../../lib/getSessionByCodeQuery'
import MeetingCodeField from './MeetingCodeField'
import SessionInfo from '../Intro/SessionInfo'
import { SessionInfo as ISessionInfo } from '../../lib/sessionInfo'

const MeetingEntryPoint = () => {
  const [code, setCode] = useState<string | undefined>()
  const [getSession, { loading, error, data }] = useLazyQuery<{
    sessionCollection: { items: ISessionInfo[] }
  }>(getSessionByCodeQuery, {
    variables: { code },
  })

  const onSubmit = (code: string) => {
    setCode(code)
    if (code) getSession()
  }

  if (loading) {
    return <MeetingCodeField disabled onSubmit={onSubmit} />
  }

  if (!code) {
    return <MeetingCodeField onSubmit={onSubmit} />
  }

  if (error || !data?.sessionCollection?.items?.length) {
    return <MeetingCodeField showError onSubmit={onSubmit} />
  }

  return <SessionInfo sessionInfo={data.sessionCollection.items[0]} />
}

export default MeetingEntryPoint
