import styled, { css } from 'styled-components'

import { Colors } from '../../../lib/sharedStyles'

import rightArrow from './../images/arrow-right.svg'
import leftArrow from './../images/arrow-left.svg'

export const Root = styled.div<{
  currentView: '0%' | '-33.33%' | '-66.66%' | undefined
}>`
  width: 300vw;
  height: 100%;

  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;

  transition: transform 0.5s;

  transform: translateX(${(props) => props.currentView});
`

export const ViewWrapper = css`
  width: 100vw;
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
  flex-shrink: 0;
`

export const MobileWrapper = styled.div`
  ${ViewWrapper};
`

export const EmbedWrapper = styled.div`
  position: relative;
  height: calc(100% - 50px);
`

export const PageLinkWrapper = styled.div`
  display: flex;
`

export const BasePageLink = css`
  appearance: none;
  border: none;
  background-color: ${Colors.Secondary};
  background-repeat: no-repeat;
  color: inherit;
  display: flex;
  align-items: center;
  font-family: inherit;
  font-size: 1.0625rem;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    display: none;
  }
`

export const PageLinkRight = styled.button`
  ${BasePageLink}

  &::after {
    content: '';
    display: inline-block;
    width: 27px;
    height: 19px;

    background-image: url(${rightArrow});
    background-position: center center;
    background-repeat: no-repeat;
    margin-left: 1rem;
  }
`

export const PageLinkLeft = styled.button`
  ${BasePageLink}

  &::before {
    content: '';
    display: inline-block;
    width: 27px;
    height: 19px;

    background-image: url(${leftArrow});
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 1rem;
  }
`
