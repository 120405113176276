import styled, { css } from 'styled-components'

export const TextBlock = css`
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #000;

  margin-bottom: 1em;

  @media all and (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
`

export const A = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const Em = styled.em`
  font-style: italic;
`

export const H2 = styled.h2`
  font-size: 1.375rem;
  line-height: 1.25;
  
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  @media all and (min-width: 768px) {
    font-size: 1.5rem
  }

  @media all and (min-width: 1200px) {
    font-size: 1.75rem
  }
`

export const Ol = styled.ol`
  ${TextBlock}

  counter-reset: ordered-list;
`

export const Strong = styled.strong`
  font-weight: bold;
`

export const Ul = styled.ul`
  ${TextBlock}
`

export const Li = styled.li`
  ${Ol} > & {
    padding-left: 2em;

    &::before {
      box-sizing: border-box;
      content: counter(ordered-list) '.';
      counter-increment: ordered-list;
      display: inline-block;
      margin-left: -2em;
      padding-right: 0.5em;
      text-align: right;
      width: 2em;
    }
  }

  ${Ul} > & {
    padding-left: 1em;

    &::before {
      content: '\\2022';
      display: inline-block;
      margin-left: -1em;
      width: 1em;
    }
  }
`

export const P = styled.p`
  ${TextBlock}

  /**
   * Contentful's rich-text feature has a bug where it inserts paragraph nodes
   * inside list-item nodes, even when there are no line breaks. To mitigate
   * this, we'll simply display a paragraph tag as though it's inline if it's
   * the only child of the list-item.
   */
  ${Li} > &:only-child {
    display: inline;
  }
`
