import React, { ReactElement, useState } from 'react'

import * as Styles from './styles'
import TwitchEmbed from '../../TwitchEmbed'

export type CurrentView = 'vote' | 'twitch' | 'chat'

const getViewWidth = (currentView: CurrentView) => {
  if (currentView === 'vote') {
    return '0%'
  }
  if (currentView === 'twitch') {
    return '-33.33%'
  }
  if (currentView === 'chat') {
    return '-66.66%'
  }
}

const Mobile: React.FC<{
  votingComponent: ReactElement
  chattingComponent: ReactElement
  twitchUsername: string
}> = ({ votingComponent, chattingComponent, twitchUsername }) => {
  const [currentView, setCurrentView] = useState<CurrentView>('vote')

  return (
    <Styles.Root currentView={getViewWidth(currentView)}>
      <Styles.MobileWrapper>
        <Styles.EmbedWrapper>{votingComponent}</Styles.EmbedWrapper>

        <Styles.PageLinkRight onClick={() => setCurrentView('twitch')}>
          Watch
        </Styles.PageLinkRight>
      </Styles.MobileWrapper>

      <Styles.MobileWrapper>
        <Styles.EmbedWrapper>
          {/**
           * For performance reasons, hide Twitch until it's selected. We
           * always keep MeetingPulse loaded, though, since it loads a bit
           * slowly.
           */}
          {currentView === 'twitch' && (
            <TwitchEmbed username={twitchUsername} />
          )}
        </Styles.EmbedWrapper>
        <Styles.PageLinkWrapper>
          <Styles.PageLinkLeft onClick={() => setCurrentView('vote')}>
            Vote
          </Styles.PageLinkLeft>
          <Styles.PageLinkRight onClick={() => setCurrentView('chat')}>
            Chat
          </Styles.PageLinkRight>
        </Styles.PageLinkWrapper>
      </Styles.MobileWrapper>

      <Styles.MobileWrapper>
        <Styles.EmbedWrapper>{chattingComponent}</Styles.EmbedWrapper>
        <Styles.PageLinkLeft onClick={() => setCurrentView('twitch')}>
          Watch
        </Styles.PageLinkLeft>
      </Styles.MobileWrapper>
    </Styles.Root>
  )
}

export default Mobile
