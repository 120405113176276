import styled from 'styled-components'

import { Colors, Shadow } from '../../lib/sharedStyles'

export const CodeOfConductAndUsername = styled.section`
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    padding: 2rem;
  }
`

export const ConductWrapper = styled.div`
  height: 47vh;
  overflow: scroll;
  margin-bottom: 2rem;
`

export const Form = styled.form`
  ${Shadow}

  display: flex;
  max-width: 450px;
`

export const Button = styled.button`
  display: block;
  flex-shrink: 0;
  padding: 1rem;

  appearance: none;
  background: ${Colors.Primary};
  border: none;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`

export const TextField = styled.input.attrs({ type: 'text' })`
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1rem;
  width: 100%;

  appearance: none;
  border: none;
  font-family: inherit;
  font-size: 1.25rem;
`
