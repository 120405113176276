import styled, { css } from 'styled-components'

import { Colors } from '../../../lib/sharedStyles'

export const Root = styled.div<{ layout: 'vote' | string }>`
  width: 100vw;
  height: 100%;
  position: relative;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  gap: 0px 0px;

  .Participate__TwitchWrapper {
    grid-area: 1 / 1 / 7 / 9;
  }

  .Participate__SuggestWrapper {
    grid-area: ${(props) =>
      props.layout === 'vote' ? '1 / 9 / 13 / 13' : '7 / 1 / 13 / 9'};
  }
  .Participate__ChatWrapper {
    grid-area: ;
    grid-area: ${(props) =>
      props.layout === 'vote' ? '7 / 1 / 13 / 9' : '1 / 9 / 13 / 13'};
  }
`

export const ViewWrapper = css`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
  flex-shrink: 0;
`

export const SuggestWrapper = styled.div`
  ${ViewWrapper};
`

export const WatchWrapper = styled.div`
  ${ViewWrapper};
`

export const ChatWrapper = styled.div`
  ${ViewWrapper};
`

export const EmbedWrapper = styled.div`
  position: relative;
  height: 100%;
`

export const ChangeViewRoot = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 4px, rgba(0, 0, 0, 0.24) 0px 3px 5px;

  background-color: ${Colors.Secondary};
  transition: bottom 0.2s ease-in;

  &:hover {
    bottom: 0;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  padding: 1rem 1.5rem;

  p {
    margin-bottom: 1rem;
  }
`

export const ChangeViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    &:nth-child(1) {
      margin-right: 1rem;
    }
  }
`

export const ChangeView = styled.div<{ isSelected: boolean }>`
  flex-grow: 1;
  border-bottom: ${(props) =>
    props.isSelected === true ? '3px solid' : 'none'};
  padding-bottom: ${(props) => (props.isSelected === true ? '0' : '3px')};
`

export const ChangeViewButton = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  color: inherit;
  display: flex;
  align-items: center;
  font-family: inherit;
  font-size: 1.0625rem;
  font-weight: bold;
  justify-content: center;

  text-transform: uppercase;
`
