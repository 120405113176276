/**
 * This component simply triggers an error (by calling a nonexistent function)
 * to test that Sentry error tracking works.
 */
import React from 'react'

const SentryTest: React.FC<{}> = () => {
  // @ts-ignore
  testSentryByCallingNonexistentFunction()

  return null
}

export default SentryTest
