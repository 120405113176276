import Countdown from 'react-countdown'
import React, { useState } from 'react'

import * as Styles from './styles'

const CountdownButton: React.FC<{
  start: string
  code: string
  showLabel?: boolean
  dark?: boolean
}> = ({ start, code, showLabel = false, dark = false }) => {
  const startDate = new Date(start)
  const [isCountdownComplete, setIsCountdownComplete] = useState(
    Date.now() > startDate.getTime(),
  )

  return (
    <>
      {showLabel && !isCountdownComplete && (
        <Styles.Label>Join session in:</Styles.Label>
      )}

      {!isCountdownComplete && (
        <Countdown
          date={startDate}
          onComplete={() => setIsCountdownComplete(true)}
          renderer={({ formatted: { days, hours, minutes, seconds } }) => (
            <Styles.Countdown dark={dark}>
              <Styles.CountdownFigure>
                <Styles.CountdownNumber>{days}</Styles.CountdownNumber>
                <Styles.CountdownLabel>days</Styles.CountdownLabel>
              </Styles.CountdownFigure>
              <Styles.CountdownDivider>:</Styles.CountdownDivider>
              <Styles.CountdownFigure>
                <Styles.CountdownNumber>{hours}</Styles.CountdownNumber>
                <Styles.CountdownLabel>hours</Styles.CountdownLabel>
              </Styles.CountdownFigure>
              <Styles.CountdownDivider>:</Styles.CountdownDivider>
              <Styles.CountdownFigure>
                <Styles.CountdownNumber>{minutes}</Styles.CountdownNumber>
                <Styles.CountdownLabel>minutes</Styles.CountdownLabel>
              </Styles.CountdownFigure>
              <Styles.CountdownDivider>:</Styles.CountdownDivider>
              <Styles.CountdownFigure>
                <Styles.CountdownNumber>{seconds}</Styles.CountdownNumber>
                <Styles.CountdownLabel>seconds</Styles.CountdownLabel>
              </Styles.CountdownFigure>
            </Styles.Countdown>
          )}
        />
      )}

      {isCountdownComplete && (
        <Styles.JoinButton to={`/participate/${code}`}>
          Join now
        </Styles.JoinButton>
      )}
    </>
  )
}

export default CountdownButton
