import styled from 'styled-components'

import { Colors, Shadow } from '../../../lib/sharedStyles'

export const Form = styled.form`
  ${Shadow}

  display: flex;
  max-width: 450px;
`

export const Button = styled.button`
  display: block;
  flex-shrink: 0;
  padding: 1rem;

  appearance: none;
  background: ${Colors.Primary};
  border: none;
  color: white;
  font-size: 2.125rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`

export const TextField = styled.input.attrs({ type: 'text' })`
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1rem;
  width: 100%;

  appearance: none;
  border: none;
  font-family: inherit;
  font-size: 2.125rem;
  font-weight: bold;
`

export const ErrorMessage = styled.p`
  background-color: ${Colors.Secondary};
  color: ${Colors.Primary};

  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
`
