import React, { useState } from 'react'

import RichText from '../RichText'
import * as Styles from './styles'

const CodeOfConductAndUsername: React.FC<{
  onSubmit: (username: string) => void
  codeOfConduct: any
}> = ({ onSubmit, codeOfConduct }) => {
  const [username, setUsername] = useState<string | undefined>()

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault()
    if (username) onSubmit(username)
  }

  return (
    <Styles.CodeOfConductAndUsername>
      <Styles.ConductWrapper>
        <RichText richTextDocument={codeOfConduct} />
      </Styles.ConductWrapper>
      <Styles.Form>
        <Styles.TextField
          placeholder="Enter your username"
          onChange={(event) => setUsername(event.target.value)}
        />
        <Styles.Button onClick={onClick}>
          Submit
        </Styles.Button>
      </Styles.Form>
    </Styles.CodeOfConductAndUsername>
  )
}

export default CodeOfConductAndUsername
