import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useStoredState } from 'react-captain'

import * as Styles from './styles'

const MeetingPulse: React.FC<{
  meetingPulseUrl: string
  participantUsername: string | null
}> = ({ meetingPulseUrl, participantUsername }) => {
  const [hasPassedRecaptcha, setHasPassedRecaptcha] = useStoredState(
    'hasPassedRecaptcha',
    'false',
  )

  const linkWithUsername = `${meetingPulseUrl}?user_name=${participantUsername}`

  return (
    <>
      {hasPassedRecaptcha === 'true' && (
        <Styles.Iframe
          src={linkWithUsername}
          title="The Slido interface for Collective Creative Mind"
          width="100%"
          height="100%"
        />
      )}

      {hasPassedRecaptcha === 'false' && (
        <Styles.RecaptchaWrappa>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
            onChange={() => setHasPassedRecaptcha('true')}
          />
        </Styles.RecaptchaWrappa>
      )}
    </>
  )
}

export default MeetingPulse
