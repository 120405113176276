import { Document } from '@contentful/rich-text-types'

export type NodeRenderer<T = any> = (
  node: T,
  key: string,
  next: NodeRenderer,
) => React.ReactNode

export interface RichTextOptions {
  renderMark?: {
    [key: string]: (text: string, key: string) => React.ReactNode
  }

  renderNode?: {
    [key: string]: NodeRenderer
  }
}

type RichTextToReactType = React.ComponentType<{
  document: Document
  options?: RichTextOptions
}>

const RichTextToReact: RichTextToReactType = require('rich-text-to-react')
  .default

export default RichTextToReact