import {
  BLOCKS,
  Document,
  Heading2,
  Hyperlink,
  INLINES,
  ListItem,
  MARKS,
  OrderedList,
  Paragraph,
  UnorderedList,
} from '@contentful/rich-text-types'
import * as React from 'react'

import RichTextToReact, { RichTextOptions } from '../../lib/rich-text-to-react'
import * as Styles from './styles'

interface Props {
  richTextDocument?: Document
  options?: RichTextOptions
}

const DEFAULT_OPTIONS: RichTextOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node: Hyperlink, key, next) => (
      <Styles.A key={ key } href={ node.data.uri } target="_blank"> {next(node.content, key, next)}</Styles.A>
    ),

    [BLOCKS.PARAGRAPH]: (node: Paragraph, key, next) => (
      <Styles.P key={key}>{next(node.content, key, next)}</Styles.P>
    ),

    [BLOCKS.OL_LIST]: (node: OrderedList, key, next) => (
      <Styles.Ol key={key}>{next(node.content, key, next)}</Styles.Ol>
    ),

    [BLOCKS.UL_LIST]: (node: UnorderedList, key, next) => (
      <Styles.Ul key={key}>{next(node.content, key, next)}</Styles.Ul>
    ),

    [BLOCKS.HEADING_2]: (node: Heading2, key, next) => (
      <Styles.H2 key={key}>{next(node.content, key, next)}</Styles.H2>
    ),

    [BLOCKS.LIST_ITEM]: (node: ListItem, key, next) => (
      <Styles.Li key={key}>{next(node.content, key, next)}</Styles.Li>
    ),

  },

  renderMark: {
    [MARKS.BOLD]: (text, key) => (
      <Styles.Strong key={key}>{text}</Styles.Strong>
    ),
    [MARKS.ITALIC]: (text, key) => <Styles.Em key={key}>{text}</Styles.Em>,
  },
}

const RichText = ({
  richTextDocument,
  options = DEFAULT_OPTIONS,
}: Props) =>
  richTextDocument ? (
    <RichTextToReact document={richTextDocument} options={options} />
  ) : null

export default RichText
