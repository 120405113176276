import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { BaseText, Colors, Shadow } from '../../../lib/sharedStyles'

export const Countdown = styled.div<{ dark?: boolean }>`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 2fr 1fr 2fr;
  justify-items: center;

  max-width: 450px;

  ${(props) =>
    props.dark
      ? css`
          background-color: ${Colors.Primary};
          ${Shadow}
          color: white;
          padding: 1.5rem 1rem;

          @media only screen and (min-width: 375px) {
            padding: 1.5rem 2rem;
          }
        `
      : ''}
`

export const CountdownFigure = styled.div`
  display: grid;
  row-gap: 1rem;
  grid-template-columns: min-content;
  justify-items: center;

  position: relative;
`

export const CountdownDivider = styled.span`
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 2.25rem;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 3.375rem;
    right: -2.5rem;
  }
`

export const CountdownNumber = styled.span`
  font-size: 2.75rem;
  font-weight: 600;

  @media only screen and (min-width: 768px) {
    font-size: 3.375rem;
  }
`

export const CountdownLabel = styled.span`
  font-size: 0.875rem;
  text-transform: uppercase;

  @media only screen and (min-width: 768px) {
    font-size: 1rem;
  }
`

export const JoinButton = styled(Link)`
  display: block;
  padding: 1rem;
  max-width: 480px;
  width: 100%;

  background: ${Colors.Primary};
  color: white;
  font-size: 2.125rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  text-decoration: none;
`

export const Label = styled.p`
  ${BaseText}

  margin-bottom: 0.5rem;
  text-align: center;
`
