import React from 'react'

import CountdownButton from '../CountdownButton'
import { SessionInfo as ISessionInfo } from '../../../lib/sessionInfo'
import * as Styles from './styles'

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
})

const timeFormatter = new Intl.DateTimeFormat(undefined, {
  hour: 'numeric',
  minute: '2-digit',
})

const SessionInfo: React.FC<{
  sessionInfo: ISessionInfo
}> = ({ sessionInfo: { title, start, end, artistName, artistUrl, code } }) => {
  const startTime = timeFormatter
    .formatToParts(new Date(start))
    .map(({ value }) => value)
    .join('')

  const endTime = timeFormatter
    .formatToParts(new Date(end))
    .map(({ value }) => value)
    .join('')

  const timezone = timeFormatter.resolvedOptions().timeZone

  return (
    <>
      <Styles.Article>
        <Styles.Heading>{title}</Styles.Heading>
        <Styles.Time>
          {dateFormatter.format(new Date(start))}
          <br />
          {startTime} - {endTime} ({timezone})
          <br />
          (drop in and take part at any time)
        </Styles.Time>
        <Styles.P>
          Featured artist:{' '}
          {artistUrl && (
            <a href={artistUrl} target="_blank" rel="noopener noreferrer">
              {artistName}
            </a>
          )}
          {!artistUrl && artistName}
        </Styles.P>
      </Styles.Article>

      <Styles.CountdownWrapper>
        <CountdownButton showLabel dark start={start} code={code} />
      </Styles.CountdownWrapper>
    </>
  )
}

export default SessionInfo
