import styled from 'styled-components'

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;

  p {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #000;
    text-align: center;

    @media all and (min-width: 768px) {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
`
