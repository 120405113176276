import styled from 'styled-components'

import { BaseText, Colors, Shadow } from '../../lib/sharedStyles'

import divider from './divider.svg'
import divider2 from './divider2.svg'
import divider3 from './divider3.svg'

const PADDING_REM = 1

export const TextWrapper = styled.article`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  padding: ${PADDING_REM}rem;
`

export const MaxWidthWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const H1 = styled.h1`
  font-weight: bold;
  font-size: 4rem;
  line-height: 5rem;

  margin-bottom: 2.5rem;

  background-image: url(${divider});
  background-repeat: no-repeat;
  background-position: bottom left;

  padding-bottom: 2rem;

  @media screen and (min-width: 768px) {
    font-size: 9rem;
    line-height: 9rem;
    max-width: 60rem;
    padding-bottom: 2.75rem;
  }

  @media screen and (min-width: 768px) and (min-height: 860px) {
    max-width: 44rem;
    padding-bottom: 2.75rem;
  }
`

export const H2 = styled.h2`
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.75rem;

  margin-bottom: 1rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 5.25rem;
  }
`

export const StepsOl = styled.ol`
  ${BaseText}

  margin-top: 10rem;

  counter-reset: steps;
  list-style-type: none;

  @media screen and (min-width: 980px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    margin-bottom: 10rem;
  }
`

export const StepLi = styled.li`
  ${Shadow}

  background-color: ${Colors.Secondary};

  position: relative;
  padding: 6.5rem 5rem 2rem 1rem;
  margin: 0 -${PADDING_REM}rem 10rem;
  max-width: 30rem;

  background-repeat: no-repeat;
  background-position: top 3.875rem left 1rem;

  &:nth-child(1) {
    background-image: url(${divider});
  }

  &:nth-child(2) {
    background-image: url(${divider2});
  }

  &:nth-child(3) {
    background-image: url(${divider3});
  }

  &::before {
    counter-increment: steps;
    content: counter(steps);

    font-weight: bold;
    font-size: 5rem;
    line-height: 3.75rem;

    position: absolute;
    top: -0.5em;
    left: 1rem;
  }

  @media screen and (min-width: 980px) {
    display: flex;
    margin: 0;

    &::before {
      align-self: center;
      flex-shrink: 0;
      width: 5.5rem;

      text-align: center;
    }
  }
`

export const P = styled.p`
  ${BaseText}

  margin-bottom: 4.5rem;
  max-width: 54.5rem;
`

export const IntroText = styled(P)`
  max-width: 54.5rem;
`

export const AboveTheFold = styled.div`
  height: calc(100vh - ${PADDING_REM}rem);
  padding-top: 4rem;

  position: relative;

  margin-bottom: 8rem;

  @media screen and (min-width: 768px) {
    height: auto;
  }
`

export const DownArrowImg = styled.img`
  position: absolute;
  bottom: ${PADDING_REM}rem;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: 768px) {
    left: auto;
    right: 0;
  }
`

export const ArtistPhotoFigure = styled.figure`
  margin: 6.25rem 0 10rem;
`

export const ArtistPhotoDesktop = styled.img`
  display: none;
  width: 100%;

  @media screen and (min-width: 768px) {
    display: initial;
  }
`

export const ArtistPhotoMobile = styled.img`
  width: 100%;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

export const SessionsWrapper = styled.div`
  display: grid;
  row-gap: 1rem;

  margin-bottom: 7rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
`

export const Footer = styled.footer`
  margin-top: 7rem;
`

export const FooterLink = styled.a`
  ${BaseText}

  display: inline-block;
  margin-right: 2rem;
`
