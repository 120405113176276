import React from 'react'

import * as Styles from './styles'

const TwitchEmbed: React.FC<{ username: string }> = ({ username }) => (
  <Styles.Iframe
    src={`https://player.twitch.tv/?channel=${username}&parent=psich.art`}
    title="Twitch stream embed"
    scrolling="no"
    height="100%"
    width="100%"
  />
)

export default TwitchEmbed
