import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'

import getSessionByCodeQuery from '../../lib/getSessionByCodeQuery'
import MeetingPulse from '.'
import MeetingPulseNotFound from './MeetingPulseNotFound'
import { SessionInfo } from '../../lib/sessionInfo'

const MeetingPulseGraphQLWrapper: React.FC<{ username: string | null }> = ({
  username,
}) => {
  const { code } = useParams()

  const { loading, error, data } = useQuery<{
    sessionCollection: { items: SessionInfo[] }
  }>(getSessionByCodeQuery, {
    variables: { code },
  })

  if (loading) return null

  if (error || !data?.sessionCollection?.items?.length) {
    return <MeetingPulseNotFound code={code} />
  }

  return (
    <MeetingPulse
      participantUsername={username}
      meetingPulseUrl={data.sessionCollection.items[0].meetingPulseUrl}
    />
  )
}

export default MeetingPulseGraphQLWrapper
