import { useQuery } from '@apollo/client'
import React, { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'

import getSessionByCodeQuery from '../lib/getSessionByCodeQuery'
import { SessionInfo } from '../lib/sessionInfo'

const SessionContext = createContext<SessionInfo[] | undefined>(undefined)

const useSessionContext = () => useContext(SessionContext)

const SessionContextProvider: React.FC = ({ children }) => {
  const { code } = useParams()

  const { loading, data } = useQuery<{
    sessionCollection: { items: SessionInfo[] }
  }>(getSessionByCodeQuery, {
    variables: { code },
  })

  if (loading) return null

  const sessionDetails = data?.sessionCollection.items

  return (
    <SessionContext.Provider value={sessionDetails}>
      {children}
    </SessionContext.Provider>
  )
}

export { SessionContextProvider, useSessionContext }
