import React, { useState } from 'react'

import * as Styles from './styles'

const MeetingCodeField: React.FC<{
  disabled?: boolean
  showError?: boolean
  onSubmit: (code: string) => void
}> = ({ disabled, showError, onSubmit }) => {
  const [code, setCode] = useState<string | undefined>()

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault()
    if (code) onSubmit(code)
  }

  return (
    <>
      {showError && (
        <Styles.ErrorMessage>
          We couldn't find the session code you entered. Please try again.
        </Styles.ErrorMessage>
      )}

      <Styles.Form>
        <Styles.TextField
          placeholder="Session code"
          onChange={(event) => setCode(event.target.value)}
          disabled={disabled}
        />
        <Styles.Button onClick={onClick} disabled={disabled || !code}>
          Go
        </Styles.Button>
      </Styles.Form>
    </>
  )
}

export default MeetingCodeField
