import React, { useEffect, useState } from 'react'

import CodeOfConductAndUsername from '../CodeOfConductAndUsername'
import DeadSimpleChat from '../DeadSimpleChat'
import MeetingPulseGraphQLWrapper from '../MeetingPulse/MeetingPulseGraphQLWrapper'
import Mobile from './Mobile'
import Desktop from './Desktop'
import { useSessionContext } from '../SessionContext'
import { useWindowSize } from './hooks'

const Participate: React.FC<{}> = () => {
  const [username, setUsername] = useState<string | null>(null)
  const result = useSessionContext()
  const windowSize = useWindowSize()

  const code = result && result[0].code
  const codeofConductText = result && result[0].codeOfConduct.json
  const twitchUsername = result && result[0].twitchUsername
  const storageKey = `Psich__username__${code}`

  const onSubmit = (username: string) => {
    setUsername(username)
    window.localStorage.setItem(storageKey, username)
  }

  useEffect(() => {
    if (window.localStorage.getItem(storageKey)) {
      setUsername(window.localStorage.getItem(storageKey))
    }
  }, [storageKey])

  const CodeOfConductOrVote = () => {
    return (
      <>
        {username && <MeetingPulseGraphQLWrapper username={username} />}
        {!username && (
          <CodeOfConductAndUsername
            codeOfConduct={codeofConductText}
            onSubmit={onSubmit}
          />
        )}
      </>
    )
  }

  const ChattingComponent = () => {
    return <DeadSimpleChat username={username} />
  }

  return (
    <>
      {windowSize.width && windowSize.width < 1024 && (
        <Mobile
          twitchUsername={twitchUsername as string}
          votingComponent={<CodeOfConductOrVote />}
          chattingComponent={<ChattingComponent />}
        />
      )}
      {windowSize.width && windowSize.width >= 1024 && (
        <Desktop
          twitchUsername={twitchUsername as string}
          votingComponent={<CodeOfConductOrVote />}
          chattingComponent={<ChattingComponent />}
        />
      )}
    </>
  )
}

export default Participate
